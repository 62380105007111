import React from 'react';
import Axios from 'axios';

//img
import logo from '../assets/img/rg-logo-login-wide.png'

//styles
import './comming-soon.styles.css'

class ComingSoon extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            emailSubscribe: false,
            email: "",
            errorMessage: "",
        }
    }

    onEmailSubscribe = (event) => {
        event.preventDefault()
        const { emailSubscribe, email } = this.state
        Axios.post('https://reengroups.com/api/comingSoon/onEmailSubscribe.php', { email: email })
            .then(response => {
                console.log(response.data)
                if (response.data.subscribeSuccess) {
                    this.setState({
                        emailSubscribe: !emailSubscribe
                    })
                } else {
                    this.setState({
                        errorMessage: response.data.message
                    })
                }
            })
            .catch(error => {
                if (error) {
                    this.setState({
                        errorMessage: 'Failed.'
                    })
                }

            })
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    render() {
        const { emailSubscribe, errorMessage } = this.state
        return (
            <div>
                {!emailSubscribe
                    ?
                    <div className="coming-soon-content">
                        <img alt='rg-logo' src={logo} />
                        <h1 className="coming-soon-title">WE ARE COMING SOON</h1>
                        <h6 className="coming-soon-text">Leave your email and we'll let you know once the site goes live.</h6>
                        <h6 className="coming-soon-text">Be the first who join our worldwide community.</h6>
                        <p className="coming-soon-text">Dedicated social network for reenactors is coming!</p>
                        <div className="subscribe-footer">
                            <div className="newsletter-box">
                                <form name="sign-up">
                                    <input
                                        type="email"
                                        className="input"
                                        id="email"
                                        name="email"
                                        placeholder="Email address"
                                        onChange={this.handleChange}
                                        required
                                    />
                                    <button
                                        onClick={this.onEmailSubscribe}
                                        className="button"
                                    >
                                        Notify Me!
                                </button>
                                </form>
                            </div>
                        </div>
                        {errorMessage ?
                            <div>
                                <p className="coming-soon-error-message">
                                    {errorMessage}
                                </p>
                            </div>
                            : null}
                        <p className="coming-soon-text-italic">Subscribe to be notified. Do not stay alone.</p>
                    </div>
                    :
                    <div className="coming-soon-content">
                        <img alt='rg-logo' src={logo} />
                        <h1 className="coming-soon-title">WE ARE COMING SOON</h1>
                        <h2 className="coming-soon-text" style={{paddingBottom: `10px`}}>Thank you!</h2>
                        <h6 className="coming-soon-text">We registered your e-mail and will get back soon.</h6>
                        <h6 className="coming-soon-text">Something awesome is in the works!</h6>
                    </div>
                }
                <div style={{clear: `both`}}></div>
            </div>
        )
    }
}

export default ComingSoon;