import React from 'react';

//pages
import ComingSoon from './pages/coming-soon.component';

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <ComingSoon />
      </div>
    )
  }
}

export default App;
